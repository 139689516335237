/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.7.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	ErrorResult,
	GetMemberEmploymentsParams,
	PagingResultOfEmploymentDto,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Get all employments for the specified member.
 */
export const getMemberEmployments = (
	memberId: string,
	params?: GetMemberEmploymentsParams,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<PagingResultOfEmploymentDto>(
		{ url: `/api/members/${memberId}/employments`, method: 'get', params, signal },
		options,
	);
};

export const getGetMemberEmploymentsQueryKey = (memberId: string, params?: GetMemberEmploymentsParams) =>
	[`/api/members/${memberId}/employments`, ...(params ? [params] : [])] as const;

export const getGetMemberEmploymentsQueryOptions = <
	TData = Awaited<ReturnType<typeof getMemberEmployments>>,
	TError = ErrorType<AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	params?: GetMemberEmploymentsParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberEmployments>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getMemberEmployments>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetMemberEmploymentsQueryKey(memberId, params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberEmployments>>> = ({ signal }) =>
		getMemberEmployments(memberId, params, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!memberId, staleTime: 10000, ...queryOptions };
};

export type GetMemberEmploymentsQueryResult = NonNullable<Awaited<ReturnType<typeof getMemberEmployments>>>;
export type GetMemberEmploymentsQueryError = ErrorType<AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Get all employments for the specified member.
 */
export const useGetMemberEmployments = <
	TData = Awaited<ReturnType<typeof getMemberEmployments>>,
	TError = ErrorType<AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	params?: GetMemberEmploymentsParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberEmployments>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetMemberEmploymentsQueryOptions(memberId, params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
