/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.7.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	ErrorResult,
	GetSalaryStatisticParams,
	SalaryStatisticDto,
	SalaryStatisticProfessionDto,
	SalaryStatisticRegionDto,
	ValidationErrorResult,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Retrieves salary statistics for given region and profession.
The statistic is based on the last finished quarter.
 */
export const getSalaryStatistic = (
	professionId: number,
	params?: GetSalaryStatisticParams,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<SalaryStatisticDto[]>(
		{ url: `/api/salary-statistics/${professionId}`, method: 'get', params, signal },
		options,
	);
};

export const getGetSalaryStatisticQueryKey = (professionId: number, params?: GetSalaryStatisticParams) =>
	[`/api/salary-statistics/${professionId}`, ...(params ? [params] : [])] as const;

export const getGetSalaryStatisticQueryOptions = <
	TData = Awaited<ReturnType<typeof getSalaryStatistic>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	professionId: number,
	params?: GetSalaryStatisticParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getSalaryStatistic>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getSalaryStatistic>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetSalaryStatisticQueryKey(professionId, params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalaryStatistic>>> = ({ signal }) =>
		getSalaryStatistic(professionId, params, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!professionId, staleTime: 10000, ...queryOptions };
};

export type GetSalaryStatisticQueryResult = NonNullable<Awaited<ReturnType<typeof getSalaryStatistic>>>;
export type GetSalaryStatisticQueryError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Retrieves salary statistics for given region and profession.
The statistic is based on the last finished quarter.
 */
export const useGetSalaryStatistic = <
	TData = Awaited<ReturnType<typeof getSalaryStatistic>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	professionId: number,
	params?: GetSalaryStatisticParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getSalaryStatistic>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetSalaryStatisticQueryOptions(professionId, params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Retrieves professions registered in the salary statistics data for the last finished quarter.
 */
export const getSalaryStatisticProfessions = (options?: SecondParameter<typeof crmAxios>, signal?: AbortSignal) => {
	return crmAxios<SalaryStatisticProfessionDto[]>(
		{ url: '/api/salary-statistics/professions', method: 'get', signal },
		options,
	);
};

export const getGetSalaryStatisticProfessionsQueryKey = () => ['/api/salary-statistics/professions'] as const;

export const getGetSalaryStatisticProfessionsQueryOptions = <
	TData = Awaited<ReturnType<typeof getSalaryStatisticProfessions>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<typeof getSalaryStatisticProfessions>>, TError, TData>;
	request?: SecondParameter<typeof crmAxios>;
}): UseQueryOptions<Awaited<ReturnType<typeof getSalaryStatisticProfessions>>, TError, TData> & {
	queryKey: QueryKey;
} => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetSalaryStatisticProfessionsQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalaryStatisticProfessions>>> = ({ signal }) =>
		getSalaryStatisticProfessions(requestOptions, signal);

	return { queryKey, queryFn, staleTime: 10000, ...queryOptions };
};

export type GetSalaryStatisticProfessionsQueryResult = NonNullable<
	Awaited<ReturnType<typeof getSalaryStatisticProfessions>>
>;
export type GetSalaryStatisticProfessionsQueryError = ErrorType<
	ValidationErrorResult | AuthorizationErrorResult | ErrorResult
>;

/**
 * @summary Retrieves professions registered in the salary statistics data for the last finished quarter.
 */
export const useGetSalaryStatisticProfessions = <
	TData = Awaited<ReturnType<typeof getSalaryStatisticProfessions>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<typeof getSalaryStatisticProfessions>>, TError, TData>;
	request?: SecondParameter<typeof crmAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetSalaryStatisticProfessionsQueryOptions(options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Retrieves regions registered in the salary statistics data for the last finished quarter.
 */
export const getSalaryStatisticRegions = (options?: SecondParameter<typeof crmAxios>, signal?: AbortSignal) => {
	return crmAxios<SalaryStatisticRegionDto[]>(
		{ url: '/api/salary-statistics/regions', method: 'get', signal },
		options,
	);
};

export const getGetSalaryStatisticRegionsQueryKey = () => ['/api/salary-statistics/regions'] as const;

export const getGetSalaryStatisticRegionsQueryOptions = <
	TData = Awaited<ReturnType<typeof getSalaryStatisticRegions>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<typeof getSalaryStatisticRegions>>, TError, TData>;
	request?: SecondParameter<typeof crmAxios>;
}): UseQueryOptions<Awaited<ReturnType<typeof getSalaryStatisticRegions>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetSalaryStatisticRegionsQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalaryStatisticRegions>>> = ({ signal }) =>
		getSalaryStatisticRegions(requestOptions, signal);

	return { queryKey, queryFn, staleTime: 10000, ...queryOptions };
};

export type GetSalaryStatisticRegionsQueryResult = NonNullable<Awaited<ReturnType<typeof getSalaryStatisticRegions>>>;
export type GetSalaryStatisticRegionsQueryError = ErrorType<
	ValidationErrorResult | AuthorizationErrorResult | ErrorResult
>;

/**
 * @summary Retrieves regions registered in the salary statistics data for the last finished quarter.
 */
export const useGetSalaryStatisticRegions = <
	TData = Awaited<ReturnType<typeof getSalaryStatisticRegions>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<typeof getSalaryStatisticRegions>>, TError, TData>;
	request?: SecondParameter<typeof crmAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetSalaryStatisticRegionsQueryOptions(options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
