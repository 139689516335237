import { useMemo } from 'react';

import Config from '@web/config';

import { Aspect, AspectRatio } from '@web/components/media/aspectRatios';

import { QBankMedia, QBankMediaTemplate } from '@web/services/umbraco/types/basic/QBank';

export enum QBankExtension {
	PNG = 'png',
	WEBP = 'webp',
	MP4 = 'mp4',
	SVG = 'svg',
}

export type ImageBuilderOptions = {
	extension: QBankExtension;

	/** Fallback Aspect ratio, if none is defined in pipeline (default behavior) */
	aspectRatio?: Aspect;
};

export function useQBankImageBuilder(image: QBankMedia, options: ImageBuilderOptions) {
	return useMemo(() => {
		const missingDimensions = image.width === 0 && image.height === 0;
		const template = getQbankTemplate(image, options, 3840);

		if (!missingDimensions && options.aspectRatio && template) {
			const aspect = template.width / template.height;

			// QBank does not support cropping when image is smaller than the template.
			const scaledHeight = image.width > template.width
				? image.width / aspect
				: image.width / AspectRatio[options.aspectRatio];

			return {
				src: buildUrl(image.filename, template),
				width: image.width,
				height: scaledHeight,
			};
		}

		return {
			src: buildUrl(image.filename, template),
			width: template?.width || image.width,
			height: template?.height || image.height,
		};
	}, [
		image,
		options,
	]);
}

export function getQbankTemplate(image: QBankMedia, options: ImageBuilderOptions, width?: number): QBankMediaTemplate | undefined {
	const parts: string[] = [];

	if (options.extension) {
		parts.push(options.extension);
	}

	if (width) {
		parts.push(width.toString());
	}

	if (options.aspectRatio) {
		parts.push(options.aspectRatio);
	}

	const templateName = parts.join('_');

	const match = image.deployedFiles
		.find((file) => file.templateName === templateName);

	return match;
}

export function getClosestQbankTemplate(image: QBankMedia, options: ImageBuilderOptions, width: number): QBankMediaTemplate | undefined {
	const exactMatch = getQbankTemplate(image, options, width);

	if (exactMatch) {
		return exactMatch;
	}

	// Special handling for video files
	if (options.extension === QBankExtension.MP4) {
		// For videos, return a template with deploymentSiteId 2 and the original filename
		return {
			deploymentSiteId: 2,
			remoteFile: image.filename,
			templateName: `${options.extension}_${width}_${options.aspectRatio || '16:9'}`,
			width: image.width || width,
			height: image.height || Math.round(width / (options.aspectRatio ? AspectRatio[options.aspectRatio] : AspectRatio['16:9'])),
		};
	}

	// Special handling for SVG files
	if (options.extension === QBankExtension.SVG) {
		// For SVGs, create a template that uses the original file
		return {
			deploymentSiteId: 2,
			remoteFile: image.filename,
			templateName: `${options.extension}`,
			width: image.width || width,
			height: image.height || width,
		};
	}

	const matchingTemplates = image.deployedFiles
		// Filter out any files that don't match the extension and aspect ratio (if provided)
		.filter((file) => {
			if (options.aspectRatio) {
				return file.templateName?.startsWith(`${options.extension}_`)
					&& file.templateName?.endsWith(`_${options.aspectRatio}`);
			}

			return file.templateName?.startsWith(`${options.extension}_`);
		},
		)
		.sort((a, b) => a.width - b.width);

	if (matchingTemplates.length === 1) {
		return matchingTemplates[0];
	}

	const closestWidth = matchingTemplates
		.reduce((prev, curr) => {
			const currWidth = curr.width;

			// If we have a width and the current width is larger than the wanted width
			// and the difference between the current width and the wanted width is less than the difference between the previous width and the wanted width
			// then return the current width
			if (currWidth > width && Math.abs(currWidth - width) < Math.abs(prev - width)) {
				return currWidth;
			}

			return prev;
		}, 0);

	const closestMatch = matchingTemplates
		.find((file) => file.width === closestWidth);

	if (closestMatch) {
		return closestMatch;
	}

	if (matchingTemplates.length) {
		return matchingTemplates[0];
	}
}

export function buildUrl(fileName: string, template?: QBankMediaTemplate) {
	if (template) {
		if (template.deploymentSiteId === 2) {
			return `${Config.QBANK.url}/v2/${encodeURI(template.remoteFile)}`;
		}

		return `${Config.QBANK.url}/${encodeURI(template.remoteFile)}`;
	}

	if (fileName) {
		// Some filenames in qbank contain uppercase file extensions
		// but they will only be available from the qbank cdn with lowercase file extensions.
		const remoteFile = lowercaseExtension(fileName);

		return `${Config.QBANK.url}/${encodeURI(remoteFile)}`;
	}

	return '';
}

function lowercaseExtension(fileName: string) {
	const parts = fileName.split('.');

	const firstParts = parts
		.slice(0, parts.length - 1)
		.join('.');

	const extension = parts[parts.length - 1];

	return `${firstParts}.${extension.toLowerCase()}`;
}
