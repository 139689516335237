/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.7.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	CalendarItemDto,
	ErrorResult,
	GetMemberCalendarItemByFilterParams,
	PagingResultOfCalendarItemDto,
	ValidationErrorResult,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Retrieves a specific calendar item.
 */
export const getMemberCalendarItemById = (
	memberId: string,
	calendarItemId: string,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<CalendarItemDto>(
		{ url: `/api/members/${memberId}/calendar-items/${calendarItemId}`, method: 'get', signal },
		options,
	);
};

export const getGetMemberCalendarItemByIdQueryKey = (memberId: string, calendarItemId: string) =>
	[`/api/members/${memberId}/calendar-items/${calendarItemId}`] as const;

export const getGetMemberCalendarItemByIdQueryOptions = <
	TData = Awaited<ReturnType<typeof getMemberCalendarItemById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	calendarItemId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberCalendarItemById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getMemberCalendarItemById>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetMemberCalendarItemByIdQueryKey(memberId, calendarItemId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberCalendarItemById>>> = ({ signal }) =>
		getMemberCalendarItemById(memberId, calendarItemId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(memberId && calendarItemId), staleTime: 10000, ...queryOptions };
};

export type GetMemberCalendarItemByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMemberCalendarItemById>>>;
export type GetMemberCalendarItemByIdQueryError = ErrorType<
	ValidationErrorResult | AuthorizationErrorResult | ErrorResult
>;

/**
 * @summary Retrieves a specific calendar item.
 */
export const useGetMemberCalendarItemById = <
	TData = Awaited<ReturnType<typeof getMemberCalendarItemById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	calendarItemId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberCalendarItemById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetMemberCalendarItemByIdQueryOptions(memberId, calendarItemId, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Get calendar items for the specified member.
 */
export const getMemberCalendarItemByFilter = (
	memberId: string,
	params?: GetMemberCalendarItemByFilterParams,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<PagingResultOfCalendarItemDto>(
		{ url: `/api/members/${memberId}/calendar-items`, method: 'get', params, signal },
		options,
	);
};

export const getGetMemberCalendarItemByFilterQueryKey = (
	memberId: string,
	params?: GetMemberCalendarItemByFilterParams,
) => [`/api/members/${memberId}/calendar-items`, ...(params ? [params] : [])] as const;

export const getGetMemberCalendarItemByFilterQueryOptions = <
	TData = Awaited<ReturnType<typeof getMemberCalendarItemByFilter>>,
	TError = ErrorType<AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	params?: GetMemberCalendarItemByFilterParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberCalendarItemByFilter>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getMemberCalendarItemByFilter>>, TError, TData> & {
	queryKey: QueryKey;
} => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetMemberCalendarItemByFilterQueryKey(memberId, params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberCalendarItemByFilter>>> = ({ signal }) =>
		getMemberCalendarItemByFilter(memberId, params, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!memberId, staleTime: 10000, ...queryOptions };
};

export type GetMemberCalendarItemByFilterQueryResult = NonNullable<
	Awaited<ReturnType<typeof getMemberCalendarItemByFilter>>
>;
export type GetMemberCalendarItemByFilterQueryError = ErrorType<AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Get calendar items for the specified member.
 */
export const useGetMemberCalendarItemByFilter = <
	TData = Awaited<ReturnType<typeof getMemberCalendarItemByFilter>>,
	TError = ErrorType<AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	params?: GetMemberCalendarItemByFilterParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberCalendarItemByFilter>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetMemberCalendarItemByFilterQueryOptions(memberId, params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
