/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.7.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type { IsAliveResponse } from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary An endpoint that can be used to get the current state of the API.
The endpoint is used for monitoring and serves as a convenience to API clients.
 */
export const isAlive = (options?: SecondParameter<typeof crmAxios>, signal?: AbortSignal) => {
	return crmAxios<IsAliveResponse>({ url: '/api/isalive', method: 'get', signal }, options);
};

export const getIsAliveQueryKey = () => ['/api/isalive'] as const;

export const getIsAliveQueryOptions = <
	TData = Awaited<ReturnType<typeof isAlive>>,
	TError = ErrorType<unknown>,
>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<typeof isAlive>>, TError, TData>;
	request?: SecondParameter<typeof crmAxios>;
}): UseQueryOptions<Awaited<ReturnType<typeof isAlive>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getIsAliveQueryKey();
	const queryFn: QueryFunction<Awaited<ReturnType<typeof isAlive>>> = ({ signal }) => isAlive(requestOptions, signal);

	return { queryKey, queryFn, staleTime: 10000, ...queryOptions };
};

export type IsAliveQueryResult = NonNullable<Awaited<ReturnType<typeof isAlive>>>;
export type IsAliveQueryError = ErrorType<unknown>;

/**
 * @summary An endpoint that can be used to get the current state of the API.
The endpoint is used for monitoring and serves as a convenience to API clients.
 */
export const useIsAlive = <TData = Awaited<ReturnType<typeof isAlive>>, TError = ErrorType<unknown>>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<typeof isAlive>>, TError, TData>;
	request?: SecondParameter<typeof crmAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getIsAliveQueryOptions(options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
