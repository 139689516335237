/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.7.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	DepartmentDto,
	ErrorResult,
	GetDepartmentsByFilterParams,
	PagingResultOfDepartmentDto,
	ValidationErrorResult,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Retrieves all departments.
 */
export const getDepartmentsByFilter = (
	params?: GetDepartmentsByFilterParams,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<PagingResultOfDepartmentDto>({ url: '/api/departments', method: 'get', params, signal }, options);
};

export const getGetDepartmentsByFilterQueryKey = (params?: GetDepartmentsByFilterParams) =>
	['/api/departments', ...(params ? [params] : [])] as const;

export const getGetDepartmentsByFilterQueryOptions = <
	TData = Awaited<ReturnType<typeof getDepartmentsByFilter>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	params?: GetDepartmentsByFilterParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getDepartmentsByFilter>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getDepartmentsByFilter>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetDepartmentsByFilterQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getDepartmentsByFilter>>> = ({ signal }) =>
		getDepartmentsByFilter(params, requestOptions, signal);

	return { queryKey, queryFn, staleTime: 10000, ...queryOptions };
};

export type GetDepartmentsByFilterQueryResult = NonNullable<Awaited<ReturnType<typeof getDepartmentsByFilter>>>;
export type GetDepartmentsByFilterQueryError = ErrorType<
	ValidationErrorResult | AuthorizationErrorResult | ErrorResult
>;

/**
 * @summary Retrieves all departments.
 */
export const useGetDepartmentsByFilter = <
	TData = Awaited<ReturnType<typeof getDepartmentsByFilter>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	params?: GetDepartmentsByFilterParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getDepartmentsByFilter>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetDepartmentsByFilterQueryOptions(params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Retrieves a specific department.
 */
export const getDepartmentById = (
	departmentId: string,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<DepartmentDto>({ url: `/api/departments/${departmentId}`, method: 'get', signal }, options);
};

export const getGetDepartmentByIdQueryKey = (departmentId: string) => [`/api/departments/${departmentId}`] as const;

export const getGetDepartmentByIdQueryOptions = <
	TData = Awaited<ReturnType<typeof getDepartmentById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	departmentId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getDepartmentById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getDepartmentById>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetDepartmentByIdQueryKey(departmentId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getDepartmentById>>> = ({ signal }) =>
		getDepartmentById(departmentId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!departmentId, staleTime: 10000, ...queryOptions };
};

export type GetDepartmentByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDepartmentById>>>;
export type GetDepartmentByIdQueryError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Retrieves a specific department.
 */
export const useGetDepartmentById = <
	TData = Awaited<ReturnType<typeof getDepartmentById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	departmentId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getDepartmentById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetDepartmentByIdQueryOptions(departmentId, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
