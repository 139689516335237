import { useEffect, useState } from 'react';
import type { NextPage } from 'next';
import { useSession } from 'next-auth/react';

import { Container } from '@dansk-metal/ui';
import { dayJS } from '@dansk-metal/utils/date';

import { BlockType } from '@web/blocks';

import { ContentBlock } from '@web/components/block-list/content-block';
import { CalendarItem } from '@web/components/calendar-item/calendar-item';
import { ErrorFrame } from '@web/components/error-frame/error-frame';
import HeroTitle from '@web/components/hero-block/hero-title/hero-title';
import { JobSearchAssessmentStatus } from '@web/components/jobSearchAssessmentStatus/job-search-assessment-status';
import { Modal } from '@web/components/modal/modal';
import { TabBar } from '@web/components/tab-bar/tab-bar';
import { useQueryParamsTabBar } from '@web/components/tab-bar/use-tab-bar.hook';
import { ThemedSkeleton } from '@web/components/themed-skeleton/themed-skeleton';
import { UmbracoLink } from '@web/components/umbraco-link/umbraco-link';

import TemplateLayout from '@web/layouts/template/template';

import { useAuthenticatedCRMRequestConfig } from '@web/services/crm-api/hooks';
import { useGetCompanyById, useGetMemberById, useGetMemberCalendarItemByFilter, useGetMemberTasksByFilter, useGetPositionById } from '@web/services/crm-api/rest';
import { crmError } from '@web/services/errorhandler/appError';
import { HeroAlignment } from '@web/services/umbraco/types/basic/Alignment';
import { BackgroundColor } from '@web/services/umbraco/types/basic/Color';
import { UmbracoErrorType } from '@web/services/umbraco/types/basic/ErrorTypes';
import { Link as UmbracoLinkType } from '@web/services/umbraco/types/basic/Link';
import { UmbracoPage } from '@web/services/umbraco/types/documents/UmbracoDocument';

import { shouldRenderPageGroup } from '@web/templates/user-member-page/helpers/should-render-page-group';
import { MemberOverviewGroupBlock, PageGroup } from '@web/templates/user-member-page/page-group/page-group';
import { Timeline } from '@web/templates/user-member-page/timeline/timeline';
import { ToDoList } from '@web/templates/user-member-page/to-do-list/to-do-list';
import { UpdateInfoModalContent } from '@web/templates/user-member-page/update-info-modal/update-info';

import { userPreferencesStorage } from '@web/utils/user-preferences-storage';

import styles from './user-member-page.module.scss';

export const contentAlias = 'userMemberPage' as const;

export type UserMemberPageData = UmbracoPage<
	typeof contentAlias,
	{
		menu: UmbracoLinkType[];
		hero: {
			title: string;
			subtitle?: string;
		};
		calender: {
			title: string;
			link: UmbracoLinkType;
		};
		tasks: {
			title: string;
			link?: UmbracoLinkType;
			enabled: boolean;
		};
		unionRepOverviewPage?: UmbracoLinkType | null;
		title: string;
		updateContactLink: UmbracoLinkType;
		updateWorkplaceLink: UmbracoLinkType;
		groups: MemberOverviewGroupBlock[];
		blocks: BlockType[];
	}
>;

const MemberOverviewPageTemplate: NextPage<{ pageData: UserMemberPageData }> = ({ pageData }) => {
	const { status } = useSession();
	const [showPopup, setShowPopup] = useState(false);
	const { hero, calender, tasks, groups, blocks, title, updateContactLink, updateWorkplaceLink } = pageData.properties || {};
	const { memberId, requestConfig, isMember } = useAuthenticatedCRMRequestConfig();

	const { data: member, isLoading: isMemberLoading } = useGetMemberById(memberId, {
		request: requestConfig,
		query: { enabled: isMember },
	});

	const [currentTime] = useState(dayJS().startOf('day').toISOString());
	const tabNames = ['Aftaler', 'Tjekliste'];
	const { activeTab, setActiveTab } = useQueryParamsTabBar({ tabs: tabNames, queryParam: 'tab' });
	const numberOfTasksToShow = 5;
	const memberCompanyId = member?.employer?.companyId ? member.employer.companyId : '';

	const {
		data: company,
		isLoading: companyIsLoading,
	} = useGetCompanyById(memberCompanyId, { query: { enabled: isMember && !!memberCompanyId }, request: requestConfig });

	const positionId = member?.positionId ? member.positionId : '';

	const {
		data: position,
		isLoading: positionIsLoading,
	} = useGetPositionById(positionId, { query: { enabled: isMember && !!positionId }, request: requestConfig });

	const { data: calendarItems, isLoading: isCalendarLoading, error: calendarItemsError } = useGetMemberCalendarItemByFilter(memberId, {
		pageSize: 3,
		start: currentTime,
	}, {
		query: {
			enabled: isMember && member?.flags?.isUnemployed,
		},
		request: requestConfig,
	});

	const { data: memberTasks, isLoading: isMemeberTasksLoading, error: memberTasksError } = useGetMemberTasksByFilter(
		memberId,
		{},
		{
			query: {
				enabled: isMember && member?.flags?.isUnemployed && tasks?.enabled,
			},
			request: requestConfig,
		});

	const isLoading = (isMemberLoading && !!memberId) || isCalendarLoading || isMemeberTasksLoading;

	const handleDismissPopup = () => {
		userPreferencesStorage.setDismissEditProfilePopup();
		setShowPopup(false);
	};

	useEffect(() => {
		const shouldDismissPopup = async () => {
			const dismissed = await userPreferencesStorage.isEditProfilePopupDismissed();
			setShowPopup(!dismissed);
		};

		if (isMember) {
			shouldDismissPopup();
		}
	}, [isMember]);

	if (status === 'unauthenticated') {
		return <ErrorFrame errorType={UmbracoErrorType.Unauthorized} />;
	}

	if (calendarItemsError || memberTasksError) {
		throw crmError({ cause: calendarItemsError || memberTasksError as unknown as Error });
	}

	return (
		<TemplateLayout>
			{hero.title && (
				<HeroTitle
					title={hero.title}
					subtitle={hero.subtitle}
					alignment={HeroAlignment.Left}
					background={BackgroundColor.White}
				/>
			)}
			{isLoading && (
				<Container className={styles.loader_wrapper}>
					<ThemedSkeleton className={styles.loader} />
				</Container>
			)}
			{!isLoading && member?.flags?.isUnemployed && calendarItems && !tasks.enabled && (
				<div className={styles.calendar_container}>
					<Timeline
						title={calender.title}
						allCalendarItemsLink={calender.link}
						calendarItems={calendarItems.items}
					/>
				</div>
			)}
			{!isLoading && member?.flags?.isUnemployed && memberTasks && tasks.enabled && (
				<>
					<Container className={styles.tabs_wrapper}>
						<TabBar tabs={tabNames} activeTab={activeTab} setActiveTab={setActiveTab} />

					</Container>
					<div className={styles.calendar_container}>
						{member?.jobSearchAssessmentColorCode && (
							<Container className={styles.job_search_assessment_status_container}>
								<JobSearchAssessmentStatus jobSearchAssessmentColorCode={member.jobSearchAssessmentColorCode} />
							</Container>
						)}
						<div className={styles.tabs_sections}>
							{activeTab === 'Aftaler' && (
								<Timeline
									title={calender.title}
									allCalendarItemsLink={calender.link}
									calendarItems={calendarItems?.items || []}
								/>
							)}
							{activeTab === 'Tjekliste' && (
								<Container className={styles.to_do_list_container}>
									<ToDoList
										tasks={memberTasks.slice(0, numberOfTasksToShow)}
										allTasksLink={tasks.link}
									/>
								</Container>
							)}
						</div>
						<Container className={styles.timeline_and_tasks}>
							<div className={styles.calendar}>
								<h2 className='h4'>Kommende aftaler</h2>
								<div className={styles.calendar__items}>
									{calendarItems && calendarItems.items.length > 0 ? (
										<>
											{calendarItems.items.map((item) => (
												<CalendarItem
													key={item.id}
													startTime={item.start}
													endTime={item.end}
													title={item.name}
													location={item.location}
													className={styles.horizontal_layout}
													href={`/medlem/booking/${item.id}`}
												/>
											))}
										</>
									) : (
										<div className={styles.empty_state_container}>
											<p>Du har ingen kommende aftaler lige nu - tjek igen senere.</p>
										</div>
									)}
								</div>
								{calender.link && (
									<div>
										<UmbracoLink
											className={styles.link}
											icon={{ icon: 'arrow-right', size: 24 }}
											link={calender.link}
										/>
									</div>
								)}
							</div>
							<div className={styles.tasks}>
								<ToDoList title={tasks.title} tasks={memberTasks.slice(0, numberOfTasksToShow)} allTasksLink={tasks.link} />
							</div>
						</Container>
					</div>
				</>
			)}
			{/* TODO: implement ordering based on ruleset */}
			{!isLoading && groups
				.filter((group) => (isMember ? shouldRenderPageGroup(group.contentProperties.userDataRuleset, member) : true))
				.map((group) => (
					<PageGroup key={group.key} group={group} />
				))
			}
			{blocks && <ContentBlock blocks={blocks} />}
			{!(isMemberLoading && companyIsLoading && positionIsLoading) && (
				<Modal
					open={showPopup}
					canCloseOutside={false}
				>
					<UpdateInfoModalContent
						title={title}
						updateContactLink={updateContactLink}
						updateWorkplaceLink={updateWorkplaceLink}
						member={member}
						position={position}
						company={company}
						handleDismissPopup={handleDismissPopup}
					/>
				</Modal>
			)}
		</TemplateLayout>
	);
};

export default MemberOverviewPageTemplate;
