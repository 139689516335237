import Img, { ImageProps } from 'next/legacy/image';
import cn from 'classnames';
import { OmitStrict } from 'type-zoo/types';

import { determineAltText } from '@web/components/qbank-image/alt-text';
import { buildUrl, getClosestQbankTemplate, QBankExtension } from '@web/components/qbank-image/loader';

import { QBankVectorType } from '@web/services/umbraco/types/basic/QBank';

import styles from './qbank-vector.module.scss';

export type QBankVectorProps = {
	media: QBankVectorType;
	height?: string | number;
	width?: string | number;
	alt?: string;
} & OmitStrict<ImageProps, 'src' | 'width' | 'height'>;

export function QBankVector({
	media,
	className,
	width,
	height,
	alt,
	...rest
}: QBankVectorProps) {
	const vectorTemplate = getClosestQbankTemplate(
		media,
		{ extension: QBankExtension.SVG },
		media.width || 640,
	);

	// Use provided dimensions or fall back to original dimensions
	const finalWidth = width || media.width || 640;
	const finalHeight = height || media.height || 480;

	return (
		<div style={{ width: finalWidth, height: finalHeight }} className={cn(styles.container, className)}>
			<Img
				unoptimized
				alt={determineAltText(media, alt)}
				src={buildUrl(media.filename, vectorTemplate)}
				layout="fill"
				loader={({ src }) => src}
				{...rest}
			/>
		</div>
	);
}
