/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.7.0
 */
import type { MutationFunction, UseMutationOptions } from 'react-query';
import { useMutation } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	EducationPlanCreateEducationPlanBody,
	ValidationErrorResult,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Post education plan form
 */
export const educationPlanCreateEducationPlan = (
	educationPlanCreateEducationPlanBody: EducationPlanCreateEducationPlanBody,
	options?: SecondParameter<typeof crmAxios>,
) => {
	return crmAxios<void>(
		{
			url: '/api/education-plan',
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: educationPlanCreateEducationPlanBody,
		},
		options,
	);
};

export const getEducationPlanCreateEducationPlanMutationOptions = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof educationPlanCreateEducationPlan>>,
		TError,
		{ data: EducationPlanCreateEducationPlanBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof educationPlanCreateEducationPlan>>,
	TError,
	{ data: EducationPlanCreateEducationPlanBody },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof educationPlanCreateEducationPlan>>,
		{ data: EducationPlanCreateEducationPlanBody }
	> = (props) => {
		const { data } = props ?? {};

		return educationPlanCreateEducationPlan(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type EducationPlanCreateEducationPlanMutationResult = NonNullable<
	Awaited<ReturnType<typeof educationPlanCreateEducationPlan>>
>;
export type EducationPlanCreateEducationPlanMutationBody = EducationPlanCreateEducationPlanBody;
export type EducationPlanCreateEducationPlanMutationError = ErrorType<ValidationErrorResult | AuthorizationErrorResult>;

/**
 * @summary Post education plan form
 */
export const useEducationPlanCreateEducationPlan = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof educationPlanCreateEducationPlan>>,
		TError,
		{ data: EducationPlanCreateEducationPlanBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}) => {
	const mutationOptions = getEducationPlanCreateEducationPlanMutationOptions(options);

	return useMutation(mutationOptions);
};
