/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.7.0
 */
import type { MutationFunction, UseMutationOptions } from 'react-query';
import { useMutation } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	ContactPostCallSubmissionBody,
	ContactPostContactFormBody,
	ErrorResult,
	ValidationErrorResult,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Post contact form
 */
export const contactPostContactForm = (
	contactPostContactFormBody: ContactPostContactFormBody,
	options?: SecondParameter<typeof crmAxios>,
) => {
	return crmAxios<void>(
		{
			url: '/api/contact/contact-form',
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: contactPostContactFormBody,
		},
		options,
	);
};

export const getContactPostContactFormMutationOptions = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof contactPostContactForm>>,
		TError,
		{ data: ContactPostContactFormBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof contactPostContactForm>>,
	TError,
	{ data: ContactPostContactFormBody },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof contactPostContactForm>>,
		{ data: ContactPostContactFormBody }
	> = (props) => {
		const { data } = props ?? {};

		return contactPostContactForm(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type ContactPostContactFormMutationResult = NonNullable<Awaited<ReturnType<typeof contactPostContactForm>>>;
export type ContactPostContactFormMutationBody = ContactPostContactFormBody;
export type ContactPostContactFormMutationError = ErrorType<
	ValidationErrorResult | AuthorizationErrorResult | ErrorResult
>;

/**
 * @summary Post contact form
 */
export const useContactPostContactForm = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof contactPostContactForm>>,
		TError,
		{ data: ContactPostContactFormBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}) => {
	const mutationOptions = getContactPostContactFormMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Post call submission
 */
export const contactPostCallSubmission = (
	contactPostCallSubmissionBody: ContactPostCallSubmissionBody,
	options?: SecondParameter<typeof crmAxios>,
) => {
	return crmAxios<void>(
		{
			url: '/api/contact/call-me',
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: contactPostCallSubmissionBody,
		},
		options,
	);
};

export const getContactPostCallSubmissionMutationOptions = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof contactPostCallSubmission>>,
		TError,
		{ data: ContactPostCallSubmissionBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof contactPostCallSubmission>>,
	TError,
	{ data: ContactPostCallSubmissionBody },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof contactPostCallSubmission>>,
		{ data: ContactPostCallSubmissionBody }
	> = (props) => {
		const { data } = props ?? {};

		return contactPostCallSubmission(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type ContactPostCallSubmissionMutationResult = NonNullable<
	Awaited<ReturnType<typeof contactPostCallSubmission>>
>;
export type ContactPostCallSubmissionMutationBody = ContactPostCallSubmissionBody;
export type ContactPostCallSubmissionMutationError = ErrorType<
	ValidationErrorResult | AuthorizationErrorResult | ErrorResult
>;

/**
 * @summary Post call submission
 */
export const useContactPostCallSubmission = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof contactPostCallSubmission>>,
		TError,
		{ data: ContactPostCallSubmissionBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}) => {
	const mutationOptions = getContactPostCallSubmissionMutationOptions(options);

	return useMutation(mutationOptions);
};
