/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.7.0
 */
import type { MutationFunction, UseMutationOptions } from 'react-query';
import { useMutation } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	ErrorResult,
	LogCreateLogBody,
	ValidationErrorResult,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

export const logCreateLog = (logCreateLogBody: LogCreateLogBody, options?: SecondParameter<typeof crmAxios>) => {
	return crmAxios<void>(
		{ url: '/api/log', method: 'post', headers: { 'Content-Type': 'application/json' }, data: logCreateLogBody },
		options,
	);
};

export const getLogCreateLogMutationOptions = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof logCreateLog>>, TError, { data: LogCreateLogBody }, TContext>;
	request?: SecondParameter<typeof crmAxios>;
}): UseMutationOptions<Awaited<ReturnType<typeof logCreateLog>>, TError, { data: LogCreateLogBody }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof logCreateLog>>, { data: LogCreateLogBody }> = (
		props,
	) => {
		const { data } = props ?? {};

		return logCreateLog(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type LogCreateLogMutationResult = NonNullable<Awaited<ReturnType<typeof logCreateLog>>>;
export type LogCreateLogMutationBody = LogCreateLogBody;
export type LogCreateLogMutationError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

export const useLogCreateLog = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof logCreateLog>>, TError, { data: LogCreateLogBody }, TContext>;
	request?: SecondParameter<typeof crmAxios>;
}) => {
	const mutationOptions = getLogCreateLogMutationOptions(options);

	return useMutation(mutationOptions);
};
