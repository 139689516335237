import cn from 'classnames';

import { Icon } from '@dansk-metal/ui';

import styles from './job-search-assessment-status.module.scss';


export interface JobSearchAssessmentStatusProps {
	jobSearchAssessmentColorCode: 'Green' | 'Yellow' | 'Red';
}

const smileyLink = '/faa-svar/dagpenge/jeg-vil-soege-dagpenge/jeg-er-ledig/hvad-vil-det-sige-at-staa-til-raadighed-og-vaere-aktivt-jobsoegende/aktiv-jobsoegning-og-smileyordningen';

export function JobSearchAssessmentStatus({ jobSearchAssessmentColorCode }: JobSearchAssessmentStatusProps) {
	switch (jobSearchAssessmentColorCode) {
		case 'Green':
			return (
				<div className={cn(styles.job_search_assessment_status_banner, styles.green)}>
					<Icon icon="green-smiley" size={48} />
					<p>Sådan! Din joblog er opdateret. <a href={smileyLink}>Læs mere</a></p>
				</div>
			);
		case 'Yellow':
			return (
				<div className={cn(styles.job_search_assessment_status_banner, styles.yellow)}>
					<Icon icon="yellow-smiley" size={48} />
					<p>Ups! Det er tid til, at du opdaterer din joblog. <a href={smileyLink}>Læs mere</a></p>
				</div>
			);
		case 'Red':
			return (
				<div className={cn(styles.job_search_assessment_status_banner, styles.red)}>
					<Icon icon="red-smiley" size={48} />
					<p>Hov! Din joblog skal opdateres nu. <a href={smileyLink}>Læs mere</a></p>
				</div>
			);
		default:
			return <></>;
	}
}
