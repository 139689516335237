import cn from 'classnames';

import { Container } from '@dansk-metal/ui';

import { buildUrl, getClosestQbankTemplate, QBankExtension } from '@web/components/qbank-image/loader';

import { QBankVectorType } from '@web/services/umbraco/types/basic/QBank';

import { getPhoneNumber } from '@web/utils/format-number';

import { Richtext } from '@apps/web/src/components/richtext/richtext';
import { HTMLString, RichTextContent } from '@apps/web/src/services/umbraco/types/basic/RichTextContent';
import { UmbracoDocument } from '@apps/web/src/services/umbraco/types/documents/UmbracoDocument';

import styles from './contact.module.scss';

export type ContactComponent = UmbracoDocument<
	'contactComponent',
	{
		title: HTMLString;
		subtitle: string;
		body: RichTextContent;
		illustration: QBankVectorType;
		phone: string;
	}
>;

export interface ContactProps {
	data: ContactComponent;
}

export function Contact({ data }: ContactProps) {
	const { title, subtitle, phone, body, illustration } = data.properties;

	const template = getClosestQbankTemplate(
		illustration,
		{ extension: QBankExtension.SVG },
		illustration.width || 640,
	);

	return (
		<Container className={styles.container}>
			<div className={styles.container__text}>
				<h2 className="h3" dangerouslySetInnerHTML={{ __html: title }} />
				<p className={styles.subtitle}>{subtitle}</p>
				<a className={cn('h0', styles.phoneNumber)} href={`tel:${phone.split(' ').join('')}`}>
					{getPhoneNumber(phone)}
				</a>
				<Richtext className={styles.rte} content={body} />
			</div>
			<div className={styles.container__img}>
				{/* eslint-disable-next-line @next/next/no-img-element */}
				{illustration?.filename && <img src={buildUrl(illustration?.filename, template)} alt=''/>}
			</div>
		</Container>
	);
}
